/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.full-fixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.full-absolute {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.scroll-x {
    overflow-x: auto;

    /* height */
    &::-webkit-scrollbar {
        height: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 8px;
        background-clip: content-box;
        background-color: #606060;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: #c9c9c9;
    }
}

.scroll-y {
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 8px;
        background-clip: content-box;
        background-color: #606060;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: #c9c9c9;
    }
}

.scroll-xy {
    overflow: auto;

    /* height */
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 8px;
        background-clip: content-box;
        background-color: #606060;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: #c9c9c9;
    }
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.justify-content-evenly {
    justify-content: space-evenly !important;
}

.user-select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ellipsis1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ellipsis3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.b-1 {
    border: 1px solid black;
}

// Bootstrap
.container {
    max-width: none;
    padding: 0 16px;
}

@media (min-width: 576px) {
    .container {
        max-width: none;
        padding: 0 16px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: none;
        padding: 0 16px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: none;
        padding: 0 16px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1080px;
        padding: 0;
    }
}
